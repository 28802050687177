import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/registro.css'; // Tu CSS

const Registro = ({ closeRegistro }) => {


  const navigate = useNavigate();
  // Referencia al contenedor del formulario
  const formRef = useRef(null);

  // useEffect para manejar clics fuera del formulario
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        closeRegistro(); // Llama a la función para cerrar el login
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeRegistro]);

  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const handleFileChange = (e) => {
    e.preventDefault(); // Previene el comportamiento predeterminado del navegador

    const selectedFile = e.target.files[0];
    if (selectedFile) {
      console.log('Archivo seleccionado:', selectedFile); // Verifica el archivo
      setFormData((prevData) => ({
        ...prevData,
        image: selectedFile, // Guardar la imagen en formData
      }));
    }
  };

  const handleRemoveImage = (e) => {
    e.preventDefault(); // Previene el comportamiento predeterminado del navegador
    setFormData((prevData) => ({
      ...prevData,
      image: null, // Eliminar la imagen de formData
    }));
  };

  // Inicializar formData con valores predeterminados
  const [formData, setFormData] = useState({
    nombre: '',
    apellido: '',
    cedula: '',
    correo: '',
    password: '',
    password_confirm: '',
    nacionalidad: 'Argentina', // Valor predeterminado
    genero: 'masculino',        // Valor predeterminado
    edad: '',
    direccion: '',
    country_code: '+54',        // Valor predeterminado
    celular: '',
    acuerdo: false,
    image: null, // Campo para la imagen
  });

  // Manejar cambios en los campos del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Validar el correo electrónico
  const validateEmail = (e) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setEmailError(!emailPattern.test(e.target.value));
  };

  // useEffect para validar el número de celular cuando cambian country_code o celular
  useEffect(() => {
    const { country_code, celular } = formData;
    if (country_code && celular) {
      const phonePatterns = {
        "+54": /^[0-9]{10}$/,
        "+591": /^[0-9]{8}$/,
        "+55": /^[0-9]{11}$/,
        "+56": /^[0-9]{9}$/,
        "+57": /^[0-9]{10}$/,
        "+506": /^[0-9]{8}$/,
        "+53": /^[0-9]{8}$/,
        "+593": /^[0-9]{9}$/,
        "+503": /^[0-9]{8}$/,
        "+502": /^[0-9]{8}$/,
        "+504": /^[0-9]{8}$/,
        "+52": /^[0-9]{10}$/,
        "+505": /^[0-9]{8}$/,
        "+507": /^[0-9]{8}$/,  // Panamá
        "+595": /^[0-9]{9}$/,
        "+51": /^[0-9]{9}$/,
        "+1-787": /^[0-9]{10}$/,
        "+1-809": /^[0-9]{10}$/,
        "+598": /^[0-9]{9}$/,
        "+58": /^[0-9]{10}$/,
      };

      // Limpiar el número de celular eliminando todos los caracteres que no sean dígitos
      const cleanedPhone = celular.replace(/\D/g, '');
      const pattern = phonePatterns[country_code];

      // Agregar logs para depuración
      console.log(`Validando celular: Código de país: "${country_code}", Celular: "${cleanedPhone}", Patrón: ${pattern}`);

      if (pattern) {
        setPhoneError(!pattern.test(cleanedPhone));
      } else {
        setPhoneError(true); // No hay patrón definido para el country_code seleccionado
      }
    } else {
      setPhoneError(false);
    }
  }, [formData]);


  const [Acuerdo, setAcuerdo] = useState(false);

  const handleAcuerdoUp = () => {
    setAcuerdo(true);
  }

  const handleAcuerdoDown = () => {
    setAcuerdo(false);
  }

  const [message, setMessage] = useState('');

  // Manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();

    // Verificar si hay errores antes de enviar
    if (emailError || phoneError) {
      setMessage("Por favor, corrige los errores en el formulario.");
      return;
    }

    if (formData.password !== formData.password_confirm) {
      setMessage('Las contraseñas no coinciden');
      return;
    }

    if (!formData.acuerdo) {
      setMessage('Debes aceptar los términos y condiciones.');
      return;
    }

    // Verificar si la imagen fue seleccionada
    if (!formData.image) {
      alert('Por favor, sube la imagen del comprobante.');
      return;
    }

    // Crear el objeto FormData para enviar la imagen
    const data = new FormData();

    // Añadir todos los datos del formulario
    Object.keys(formData).forEach((key) => {
      if (key !== 'image') {
        data.append(key, formData[key]); // No agregar la imagen en este ciclo
      }
    });

    // Agregar la imagen
    data.append('image_cedula', formData.image);

    // Enviar los datos al servidor
    axios.post('https://medico24x7.com/api/registro.php', data, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => {
        if (response.data.success) {
          setMessage(response.data.message);
          alert('Registro exitoso');
          navigate('/');
          closeRegistro();
        } else {
          setMessage(response.data.message);
          alert('Error en el registro: ' + response.data.message);
        }
      })
      .catch(error => {
        console.error("Hubo un error al enviar el formulario:", error);
        setMessage("Hubo un error en el registro.");
      });
  };


  return (
    <div className="registro__container">
      <main className='registro' ref={formRef}>
        <div className="registro-section">
          <h1>Registro de Usuario</h1>
          <form id="registrationForm" onSubmit={handleSubmit}>
            <fieldset>
              <legend>Datos Personales</legend>

              <label htmlFor="nombre">Nombre</label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
                required
              />

              <label htmlFor="apellido">Apellidos</label>
              <input
                type="text"
                id="apellido"
                name="apellido"
                value={formData.apellido}
                onChange={handleChange}
                required
              />

              <label htmlFor="cedula">Número de Cédula</label>
              <input
                type="text"
                id="cedula"
                name="cedula"
                value={formData.cedula}
                onChange={handleChange}
                required
              />

              <label htmlFor="image">Imagen Frontal de Cédula</label>
              <input
                type="file"
                id="image"
                name="image"
                accept="image/*"
                onChange={handleFileChange}
                className="file-input"
              />
              {formData.image && (
                <div className="image-list">
                  <div className="image-item">
                    <p>Archivo seleccionado: {formData.image.name}</p>
                    <img
                      src={URL.createObjectURL(formData.image)}
                      alt="Vista previa"
                      style={{ width: '200px', height: 'auto' }}
                    />
                    <br />
                    <button
                      type="button"
                      className="remove-button"
                      onClick={handleRemoveImage}
                    >
                      Eliminar
                    </button>
                  </div>
                </div>
              )}


              <label htmlFor="correo">Email</label>
              <input
                type="email"
                id="correo"
                name="correo"
                value={formData.correo}
                onChange={(e) => { handleChange(e); validateEmail(e); }}
                required
              />
              {emailError && <p className='p_error'>Por favor, ingresa un correo válido.</p>}

              <label htmlFor="password">Contraseña</label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />

              <label htmlFor="password_confirm">Confirmar Contraseña</label>
              <input
                type="password"
                id="password_confirm"
                name="password_confirm"
                value={formData.password_confirm}
                onChange={handleChange}
                required
              />

              <label htmlFor="nacionalidad">Nacionalidad</label>
              <select
                id="nacionalidad"
                name="nacionalidad"
                value={formData.nacionalidad}
                onChange={handleChange}
                required
              >
                <option value="Argentina">Argentina</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Brasil">Brasil</option>
                <option value="Chile">Chile</option>
                <option value="Colombia">Colombia</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cuba">Cuba</option>
                <option value="Ecuador">Ecuador</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Honduras">Honduras</option>
                <option value="México">México</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Panamá">Panamá</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Perú">Perú</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="República Dominicana">República Dominicana</option>
                <option value="Uruguay">Uruguay</option>
                <option value="Venezuela">Venezuela</option>
              </select>

              <label htmlFor="genero">Género</label>
              <select
                id="genero"
                name="genero"
                value={formData.genero}
                onChange={handleChange}
              >
                <option value="masculino">Masculino</option>
                <option value="femenino">Femenino</option>
                <option value="otro">Otro</option>
              </select>

              <label htmlFor="edad">Edad</label>
              <input
                type="number"
                id="edad"
                name="edad"
                value={formData.edad}
                onChange={handleChange}
                required
              />

              <label htmlFor="direccion">Dirección de Residencia</label>
              <input
                type="text"
                id="direccion"
                name="direccion"
                value={formData.direccion}
                onChange={handleChange}
                required
              />

              <label htmlFor="country_code">Código de País</label>
              <select
                id="country_code"
                name="country_code"
                value={formData.country_code}
                onChange={handleChange}
                required
              >
                <option value="+54">Argentina (+54)</option>
                <option value="+591">Bolivia (+591)</option>
                <option value="+55">Brasil (+55)</option>
                <option value="+56">Chile (+56)</option>
                <option value="+57">Colombia (+57)</option>
                <option value="+506">Costa Rica (+506)</option>
                <option value="+53">Cuba (+53)</option>
                <option value="+593">Ecuador (+593)</option>
                <option value="+503">El Salvador (+503)</option>
                <option value="+502">Guatemala (+502)</option>
                <option value="+504">Honduras (+504)</option>
                <option value="+52">México (+52)</option>
                <option value="+505">Nicaragua (+505)</option>
                <option value="+507">Panamá (+507)</option>
                <option value="+595">Paraguay (+595)</option>
                <option value="+51">Perú (+51)</option>
                <option value="+1-787">Puerto Rico (+1-787)</option>
                <option value="+1-809">República Dominicana (+1-809)</option>
                <option value="+598">Uruguay (+598)</option>
                <option value="+58">Venezuela (+58)</option>
                {/* Añadir más opciones de país aquí */}
              </select>

              <label htmlFor="celular">Celular</label>
              <input
                type="tel"
                id="celular"
                name="celular"
                value={formData.celular}
                onChange={handleChange}
                pattern="\d{8}"
                title="Por favor, ingresa exactamente 8 dígitos."
                required
              />
              {phoneError && <p className='p_error'>El número no es válido.</p>}
              <br />

              <label htmlFor="acuerdo" style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  id="acuerdo"
                  name="acuerdo"
                  checked={formData.acuerdo}
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      acuerdo: e.target.checked, // Actualiza el estado de acuerdo
                    }))
                  }
                />
                <p style={{ margin: '0 0 0 8px' }}>
                  Al registrarte, aceptas nuestros <span onClick={handleAcuerdoUp} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>
                    Términos y Condiciones y nuestra Política de Privacidad
                  </span>.
                </p>
              </label>
              {Acuerdo && (
                <div className='AcuerdoFlotante'>
                  <div className='AcuerdoContenido'>
                    <p>Términos y condiciones y avisos de privacidad </p>
                    <label htmlFor="acuerdo">
                      <p>Consentimiento legal autorizado por el usuario <br />
                        CONSENTIMIENTO LEGAL AUTORIZADO POR EL PACIENTE <br />
                        AUTORIZACIÓN TRATAMIENTO DE DATOS PERSONALES <br />
                        Usted está accediendo al Sitio Web medico24x7.com voluntariamente. <br />
                        Los Datos Personales por Usted suministrados serán objeto de tratamiento por parte de medico24x7, Administrador del programa, en los términos establecidos en la presente autorización. Al aceptarla expresamente, usted autoriza el tratamiento de éstos según lo aquí previsto. <br />
                        Autoriza de manera previa, expresa e informada al ADMINISTRADOR para que, directamente o a través de sus consultores, asesores y/o terceros proveedores, lleven a cabo la obtención, uso o almacenamiento de sus Datos Personales, por cualquier medio. El uso abarca cualquier acción de acceso,
                        manejo, aprovechamiento, transferencia limitada o disposición de los mismos. Autoriza transferirlos a terceros proveedores de servicios con los cuales el ADMINISTRADOR o sus socios comerciales cuentan con una relación contractual vigente y faculta al ADMINISTRADOR a celebrar dicha transferencia,
                        para el propósito del desarrollo del Programa. <br />
                        Además, autoriza a que sus Datos Personales sean compartidos dentro y fuera del país con la Dirección Médica de medico24x7, sus filiales y subordinadas, para fines de dar continuidad al Programa, y con terceros administradores del Programa designados en un futuro, para el mismo propósito. <br />
                        Puede limitar el uso o divulgación de sus Datos Personales mediante los procedimientos indicados en la Ley local, revocación de consentimiento o negativa de uso de datos personales para fines secundarios que se mencionan en el Aviso de Privacidad del Programa. Aviso de Privacidad y Términos y condiciones <br />
                        De conformidad con los documentos relacionados al Programa, los cuales se encuentran publicados en la Sección de Información Legal de Documentos | medico24x7 (medico24x7.com), Usted hace constar que ha sido debidamente informado, ha leído y expresamente acepta el contenido de dichos documentos y
                        consiente voluntariamente que sus Datos Personales sean tratados conforme a lo previsto en este consentimiento. Asimismo, declara que la información brindada es veraz.
                        Nombre y número de cédula se solicitan en la información de registro.
                      </p>
                    </label>
                    <button onClick={handleAcuerdoDown}>Cerrar</button>
                  </div>
                </div>
              )}
              <br />

              {formData.acuerdo && (
                <button type="submit">Registrarse</button>
              )}
            </fieldset>
          </form>
          {message && <p>{message}</p>}
        </div>
      </main>
    </div>
  );
};

export default Registro;
