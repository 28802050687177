import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import HomePage from './components/home';
import SoundCloudPlayer from './components/SounCloud';
//import MusicPlayer from './components/AudioPlayer';  // Componente del reproductor
import Traductor from './components/Traductor';  // Componente del traductor
import RedesSociales from './components/RedesSociales';
import MedicinaGeneral from './components/MedicinaGeneral';
//import Pagos from './components/Pagos2';
import ConsultasVirtuales from './components/ConsultasVirtuales';
import ConsultaChat from './components/ConsultaChat';
import MetodoPago from './components/metodo_pago';
import Psicologia from './components/Psicologia';
import Noticias from './components/Noticias_Blog';
import Citas from './components/Citas';
import Sugerencias from './components/Sugerencias';
import Usuarios from './components/Usuarios/Usuarios';
import Consultas from './components/Usuarios/Consultas';
import Citasusuario from './components/Usuarios/Citas_usuario';
import Recibopagos from './components/Usuarios/Recibo_pagos';
import CookieConsent from './components/CookieConsent';
import Header from './components/Header';
import Footer from './components/Footer';
import Admin from './components/Admin/Admin';
import ConsultasAdmin from './components/Admin/ConsultasAdmin';
import CitasAdmin from './components/Admin/CitasAdmin';
//import ReciboAdmin from './components/Admin/ReciboAdmin';
import PacientesDataAdmin from './components/Admin/Pacientes';
import SugerenciasAdmin from './components/Admin/SugerenciasAdmin';
import NoticiasAdmin from './components/Admin/NoticiasAdmin';
import BlogAdmin from './components/Admin/BlogAdmin';
import Login from './components/login';
import LoginAdmin from './components/Admin/LoginAdmin';
import Registro from './components/registro';
import RegistroAdmin from './components/Admin/RegistroAdmin';
import AdminDoctor from './components/AdminDoctores/AdminDoctor';
import CitasAdminDoctor from './components/AdminDoctores/Citas_Admin_Doctor';
import ConsultasAdminDoctor from './components/AdminDoctores/Consultas_Admin_Doctor';
import LoginAdminDoctor from './components/AdminDoctores/Login_Admin_Doctor';
import RegistroAdminDoctor from './components/AdminDoctores/Registro_Admin_Doctor';
import FechaAdminDoctor from './components/AdminDoctores/Fecha_Admin_Doctor';
import CertifSalud from './components/AdminDoctores/Formularios/Cert_Salud';
import SolicExamenes from './components/AdminDoctores/Formularios/Solicitud_Examenes';
import HistoriaClinica from './components/AdminDoctores/Formularios/Historia_Clinica';
import RefMedicas from './components/AdminDoctores/Formularios/Ref_Medicas';
import Recetarios from './components/AdminDoctores/Formularios/Recetarios';
import CertificadoIncapacidad from './components/AdminDoctores/Formularios/Certificado_Incapacidad';

import Gallery from './components/Dropbox/Gallery';
import UploadForm from './components/Dropbox/UploadForm';

import Patrocinios from './components/Patrocinios';
import Patrocinios2 from './components/Patrocinios2';


function App() {
  return (
    <div className="parent-app">
    <Router>
      {/*MusicPlayer*/}
      {/*<MusicPlayer />*/}
        <div className="div1-app">
          <Header />
        </div>
        <div className="div2-app">
        <Patrocinios/>
        <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/medicina-general" element={<MedicinaGeneral />} />
        {/*<Route path="/pagos" element={<Pagos />} />*/}
        <Route path="/consultas-virtuales" element={<ConsultasVirtuales />} />
        <Route path="/ConsultaChat" element={<ConsultaChat />} />
        <Route path="/metodo-pago" element={<MetodoPago />} />
        <Route path="/psicologia" element={<Psicologia />} />
        <Route path="/noticias" element={<Noticias />} />
        <Route path="/citas" element={<Citas />} />
        <Route path="/sugerencias" element={<Sugerencias />} />
        <Route path="/Usuarios" element={<Usuarios />} />
        <Route path="/Consultas" element={<Consultas />} />
        <Route path="/Citas_usuario" element={<Citasusuario />} />
        <Route path="/Recibo_pagos" element={<Recibopagos />} />
        <Route path="/Admin" element={<Admin />} />
        <Route path="/Consultas_Admin" element={<ConsultasAdmin />} />
        <Route path="/Citas_Admin" element={<CitasAdmin />} />
        {/*<Route path="/Recibo_Admin" element={<ReciboAdmin />} />*/}
        <Route path="/PacientesDataAdmin" element={<PacientesDataAdmin />} />
        <Route path="/Sugerencias_Admin" element={<SugerenciasAdmin />} />
        <Route path="/Noticias_Admin" element={<NoticiasAdmin />} />
        <Route path="/Blog_Admin" element={<BlogAdmin />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Login_Admin" element={<LoginAdmin />} />
        <Route path="/Registro" element={<Registro />} />
        <Route path="/Registro_Admin" element={<RegistroAdmin />} />
        <Route path="/AdminDoctor" element={<AdminDoctor />} />
        <Route path="/Citas_Admin_Doctor" element={<CitasAdminDoctor />} />
        <Route path="/Consultas_Admin_Doctor" element={<ConsultasAdminDoctor />} />
        <Route path="/Login_Admin_Doctor" element={<LoginAdminDoctor />} />
        <Route path="/Registro_Admin_Doctor" element={<RegistroAdminDoctor />} />
        <Route path="/Fecha_Admin_Doctor" element={<FechaAdminDoctor />} />
        <Route path="/Certif_Salud" element={<CertifSalud />} />
        <Route path="/Solic_Examenes" element={<SolicExamenes />} />
        <Route path="/HistoriaClinica" element={<HistoriaClinica />} />
        <Route path="/RefMedicas" element={<RefMedicas/>} />
        <Route path="/Recetarios" element={<Recetarios/>} />
        <Route path="/CertificadoIncapacidad" element={<CertificadoIncapacidad/>} />
        <Route path="/Gallery" element={<Gallery/>} />
        <Route path="/UploadForm" element={<UploadForm/>} />
        </Routes>
        <Patrocinios2/>
        <Footer />
        </div>
        
      <CookieConsent />
      <Traductor />
      <SoundCloudPlayer />
      <RedesSociales />
      <div className="div3-app">
      </div>
    </Router>
    </div>
  );
}

export default App;