import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom'; // Importa useLocation para obtener la ruta actual
import axios from 'axios';
import Login from './login';
import Registro from './registro';
import useMenuToggle from './useMenuToggle';
import '../css/Header.css';

export default function NavBar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggedIn_admin, setIsLoggedIn_admin] = useState(false);
  const [isLoggedIn_admin_doctor, setIsLoggedIn_admin_doctor] = useState(false);
  const [user, setUser] = useState("");
  const [user_admin, setUser_admin] = useState("");
  const [user_admin_doctor, setUser_admin_doctor] = useState("");
  const navigate = useNavigate();
  const location = useLocation(); // Usa useLocation para obtener la ruta actual

  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState('initial'); // 'initial' or 'warning'

  const mobileMenuId = "mobile-menu";
  const navListId = "nav-list";

  useMenuToggle(mobileMenuId, navListId);

  // Registro de usuario nuevo
  const [showRegistro, setShowRegistro] = useState(false);
  const openRegistro = () => setShowRegistro(true);
  const closeRegistro = () => setShowRegistro(false);

  // Inicio de sesión usuario
  const [showLogin, setShowLogin] = useState(false);
  const openLogin = () => setShowLogin(true);
  const closeLogin = () => setShowLogin(false);


  useEffect(() => {
    const validarSesionUsuario = async () => {
      axios.get('https://medico24x7.com/api/check_session.php', { withCredentials: true })
        .then(response => {
          if (response.data.loggedIn) {
            setIsLoggedIn(true);
            setUser(response.data);
          } else {
            setIsLoggedIn(false);
            setUser("");
          }
        })
        .catch(error => {
          console.error("Error al verificar la sesión:", error);
        });
    }

    // Ejecutar la validación inicialmente
    validarSesionUsuario();

    // Configurar un intervalo para verificar el estado de la sesión cada cierto tiempo (por ejemplo, cada 5 segundos)
    const intervalIdUsuario = setInterval(() => {
      validarSesionUsuario();
    }, 5000); // 5000 milisegundos = 5 segundos

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalIdUsuario);

  }, []);

  useEffect(() => {
    const validarSesionAdmin = async () => {
      axios.get('https://medico24x7.com/api/Admin/check_session_admin.php', { withCredentials: true })
        .then(response => {
          if (response.data.loggedInAdmin) {
            setIsLoggedIn_admin(true);
            setUser_admin(response.data);
          } else {
            setIsLoggedIn_admin(false);
            setUser_admin("");
          }
        })
        .catch(error => {
          console.error("Error al verificar la sesión:", error);
        });
    }

    // Ejecutar la validación inicialmente
    validarSesionAdmin();

    // Configurar un intervalo para verificar el estado de la sesión cada cierto tiempo (por ejemplo, cada 5 segundos)
    const intervalIdAdmin = setInterval(() => {
      validarSesionAdmin();
    }, 5000); // 5000 milisegundos = 5 segundos

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalIdAdmin);
  }, []);


  useEffect(() => {
    const validarSesionAdminDoctor = async () => {
      axios.get('https://medico24x7.com/api/AdminDoctores/check_session_admin_doctor.php', { withCredentials: true })
        .then(response => {
          if (response.data.loggedInAdmin) {
            setIsLoggedIn_admin_doctor(true);
            setUser_admin_doctor(response.data);
          } else {
            setIsLoggedIn_admin_doctor(false);
            setUser_admin_doctor("");
          }
        })
        .catch(error => {
          console.error("Error al verificar la sesión:", error);
        });
    }

    // Ejecutar la validación inicialmente
    validarSesionAdminDoctor();

    // Configurar un intervalo para verificar el estado de la sesión cada cierto tiempo (por ejemplo, cada 5 segundos)
    const intervalIdAdmin = setInterval(() => {
      validarSesionAdminDoctor();
    }, 5000); // 5000 milisegundos = 5 segundos

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalIdAdmin);
  }, []);


  const handleNavigation = (page) => {
    navigate(page); // Navegar a la nueva página
  };

  // Comparar la ruta actual con la ruta de cada enlace para asignar la clase `nav_seleccionado`
  const getNavClass = (path) => (location.pathname === path ? 'nav_seleccionado' : 'nav');

  const Logout = () => {
    axios.get('https://medico24x7.com/api/logout.php', { withCredentials: true })
      .then(response => {
        if (response.data.success) {
          setIsLoggedIn(false); // Actualiza el estado
          alert("Sesión cerrada exitosamente");
          navigate('/');
        }
      })
      .catch(error => {
        console.error("Error al cerrar sesión:", error);
      });
  };

  const Logout_Admin = () => {
    axios.get('https://medico24x7.com/api/Admin/logout_admin.php', { withCredentials: true })
      .then(response => {
        if (response.data.success) {
          setIsLoggedIn_admin(false); // Actualiza el estado
          alert("Sesión cerrada exitosamente");
          navigate('/Admin');
        }
      })
      .catch(error => {
        console.error("Error al cerrar sesión:", error);
      });
  };

  const Logout_All_Admin = () => {
    axios.get('https://medico24x7.com/api/Admin/logout_all.php', { withCredentials: true })
      .then(response => {
        if (response.data.success) {
          setIsLoggedIn_admin(false); // Actualiza el estado
          alert("Sesión cerrada exitosamente");
          navigate('/Admin');
        }
      })
      .catch(error => {
        console.error("Error al cerrar sesión:", error);
      });
  };

  const Logout_Admin_Doctor = () => {
    axios.get('https://medico24x7.com/api/AdminDoctores/logout_admin_doctor.php', { withCredentials: true })
      .then(response => {
        if (response.data.success) {
          setIsLoggedIn_admin_doctor(false); // Actualiza el estado
          alert("Sesión cerrada exitosamente");
          navigate('/AdminDoctor');
        }
      })
      .catch(error => {
        console.error("Error al cerrar sesión:", error);
      });
  };



  const [timeLeft, setTimeLeft] = useState({ user: 1800, admin: 1800, doctor: 1800 });

  useEffect(() => {
    const resetTimers = () => setTimeLeft({ user: 1800, admin: 1800, doctor: 1800 });

    // Disminuir el tiempo cada segundo
    const interval = setInterval(() => {
      setTimeLeft((prev) => {
        const newTime = {
          user: prev.user > 0 ? prev.user - 1 : 0,
          admin: prev.admin > 0 ? prev.admin - 1 : 0,
          doctor: prev.doctor > 0 ? prev.doctor - 1 : 0,
        };

        // Mostrar mensaje flotante cuando queden 5 minutos
        if (
          (newTime.user === 300 || newTime.admin === 300 || newTime.doctor === 300) &&
          messageType !== 'warning'
        ) {
          setShowMessage(true);
          setMessageType('warning');
        }

        return newTime;
      });
    }, 1000);

    // Mostrar el mensaje inicial por 5 segundos
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 5000);

    // Reiniciar el temporizador en cualquier interacción del usuario
    const handleUserActivity = () => {
      resetTimers();
    };

    // Añadir eventos para detectar la actividad del usuario
    window.addEventListener('click', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('mousemove', handleUserActivity);

    // Limpiar eventos y el intervalo cuando el componente se desmonte
    return () => {
      clearInterval(interval);
      window.removeEventListener('click', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('mousemove', handleUserActivity);
    };
  }, [messageType]);

  /*useEffect(() => {
    const resetTimers = () => setTimeLeft({ user: 1800, admin: 1800, doctor: 1800 });
    
    // Disminuir el tiempo cada segundo
    const interval = setInterval(() => {
      setTimeLeft((prev) => ({
        user: prev.user > 0 ? prev.user - 1 : 0,
        admin: prev.admin > 0 ? prev.admin - 1 : 0,
        doctor: prev.doctor > 0 ? prev.doctor - 1 : 0,
      }));
    }, 1000);

    // Reiniciar el temporizador en cualquier interacción del usuario
    const handleUserActivity = () => {
      resetTimers();
    };

    // Añadir eventos para detectar la actividad del usuario
    window.addEventListener('click', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('mousemove', handleUserActivity);

    // Limpiar eventos y el intervalo cuando el componente se desmonte
    return () => {
      clearInterval(interval);
      window.removeEventListener('click', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('mousemove', handleUserActivity);
    };
  }, []);*/

  useEffect(() => {
    const container = document.querySelector('.containerHome');
    if (container) {
      const path = window.location.pathname;

      if (
        path === "/adminDoctor" || path === "/adminDoctor/" ||
        path === "/AdminDoctor" || path === "/AdminDoctor/" ||
        path === "/Consultas_Admin_Doctor" || path === "/Citas_Admin_Doctor" ||
        path === "/Login_Admin_Doctor" || path === "/Registro_Admin_Doctor" ||
        path === "/Fecha_Admin_Doctor" || path === "/Certif_Salud" ||
        path === "/Solic_Examenes" || path === "/HistoriaClinica" ||
        path === "/RefMedicas" || path === "/Recetarios" ||
        path === "/CertificadoIncapacidad"
      ) {
        container.style.background = 'rgb(204, 102, 0)';
      } else if (
        path === "/admin" || path === "/admin/" ||
        path === "/Admin" || path === "/Admin/" ||
        path === "/Consultas_Admin" || path === "/Citas_Admin" ||
        //path === "/Recibo_Admin" || path === "/Sugerencias_Admin" ||
        path === "/Login_Admin" || path === "/Registro_Admin" ||
        path === "/Noticias_Admin" || path === "/Blog_Admin"
      ) {
        container.style.background = 'rgb(255, 255, 0)';
      } else {
        container.style.background = 'rgb(0, 0, 128)';
      }
    }
  }, []);

  const [Chat_pago, setChat_pago] = useState(false);

  useEffect(() => {
    if (location.pathname === "/ConsultaChat") {
      setChat_pago(true);
    } else {
      setChat_pago(false);
    }
  }, [location.pathname]);



  if (location.pathname === '/pagos') { return null; }

  return (
    <div className='body_home'>

      {/* !Chat_pago ? (
      <>
      {isLoggedIn && <div className="timerHeadre">Tiempo de Inactividad: {Math.floor(timeLeft.user / 60)}:{String(timeLeft.user % 60).padStart(2, '0')}</div>}
      {isLoggedIn_admin && <div className="timerHeadre">Tiempo de Inactividad: {Math.floor(timeLeft.admin / 60)}:{String(timeLeft.admin % 60).padStart(2, '0')}</div>}
      {isLoggedIn_admin_doctor && <div className="timerHeadre">Tiempo de Inactividad: {Math.floor(timeLeft.doctor / 60)}:{String(timeLeft.doctor % 60).padStart(2, '0')}</div>}
      </>
) : ("") */}

      {!Chat_pago ? (
        <>
          {isLoggedIn && (
            <>
              {/*<div className="timerHeadre">
          Tiempo de Inactividad: {Math.floor(timeLeft.user / 60)}:
          {String(timeLeft.user % 60).padStart(2, '0')}
        </div>*/}
              {showMessage && (
                <div className="floating-message">
                  <p>
                    Tiempo restante para usuario: {Math.floor(timeLeft.user / 60)}:
                    {String(timeLeft.user % 60).padStart(2, '0')}
                  </p>
                  {messageType === 'warning' && (
                    <button onClick={() => setShowMessage(false)}>Cerrar</button>
                  )}
                </div>
              )}
            </>
          )}
          {isLoggedIn_admin && (
            <>
              {/*<div className="timerHeadre">
          Tiempo de Inactividad: {Math.floor(timeLeft.admin / 60)}:
          {String(timeLeft.admin % 60).padStart(2, '0')}
        </div>*/}
              {showMessage && (
                <div className="floating-message">
                  <p>
                    Tiempo restante para admin: {Math.floor(timeLeft.admin / 60)}:
                    {String(timeLeft.admin % 60).padStart(2, '0')}
                  </p>
                  {messageType === 'warning' && (
                    <button onClick={() => setShowMessage(false)}>Cerrar</button>
                  )}
                </div>
              )}
            </>
          )}
          {isLoggedIn_admin_doctor && (
            <>
              {/*<div className="timerHeadre">
          Tiempo de Inactividad: {Math.floor(timeLeft.doctor / 60)}:
          {String(timeLeft.doctor % 60).padStart(2, '0')}
        </div>*/}
              {showMessage && (
                <div className="floating-message">
                  <p>
                    Tiempo restante para doctor: {Math.floor(timeLeft.doctor / 60)}:
                    {String(timeLeft.doctor % 60).padStart(2, '0')}
                  </p>
                  {messageType === 'warning' && (
                    <button onClick={() => setShowMessage(false)}>Cerrar</button>
                  )}
                </div>
              )}
            </>
          )}
        </>
      ) : (
        ""
      )}


      {!Chat_pago ? (<>

        <header id="mainHeader">

          <div className="containerHome">
            {["/adminDoctor", "/adminDoctor/", "/AdminDoctor/", "/AdminDoctor", "/Consultas_Admin_Doctor",
              "/Citas_Admin_Doctor", "/Login_Admin_Doctor", "/Registro_Admin_Doctor", "/Fecha_Admin_Doctor",
              "/Certif_Salud", "/Solic_Examenes", "/HistoriaClinica", "/RefMedicas", "/Recetarios",
              "/CertificadoIncapacidad"].includes(location.pathname) ? (
              <Link to="/AdminDoctor" className="logo-name">
                <img id="logo" src={`${process.env.PUBLIC_URL}/assets/images/LOGO_no_bg.png`} alt="LOGO#1" className="logo-svg" />
              </Link>
            ) : (
              ["/Admin/", "/Admin", "/Consultas_Admin", "/Citas_Admin", "/Recibo_Admin", "/Sugerencias_Admin", "/Login_Admin", "/Registro_Admin", "/Noticias_Admin", "/Blog_Admin"].includes(location.pathname) ? (
                <Link to="/Admin" className="logo-name">
                  <img id="logo" src={`${process.env.PUBLIC_URL}/assets/images/LOGO_no_bg.png`} alt="LOGO#1" className="logo-svg" />
                </Link>
              ) : (
                <Link to="/" className="logo-name">
                  <img id="logo" src={`${process.env.PUBLIC_URL}/assets/images/LOGO_no_bg.png`} alt="LOGO#1" className="logo-svg" />
                </Link>
              )
            )}
          </div>

          <div className='menu_nav'>
            <nav>
              <div className="menu-toggle" id={mobileMenuId}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <ul className="nav-list" id={navListId}>

                {["/adminDoctor", "/adminDoctor/", "/AdminDoctor/", "/AdminDoctor", "/Consultas_Admin_Doctor",
                  "/Citas_Admin_Doctor", "/Login_Admin_Doctor", "/Registro_Admin_Doctor", "/Fecha_Admin_Doctor",
                  "/Certif_Salud", "/Solic_Examenes", "/HistoriaClinica", "/RefMedicas", "/Recetarios",
                  "/CertificadoIncapacidad"].includes(location.pathname) ? (
                  <>
                    <li><Link to="/AdminDoctor" className={getNavClass("/AdminDoctor")} onClick={() => handleNavigation("/AdminDoctor")}>INICIO</Link></li>
                    <li><Link to="/Consultas_Admin_Doctor" className={getNavClass("/Consultas_Admin_Doctor")} onClick={() => handleNavigation("/Consultas_Admin_Doctor")}>CONSULTAS VIRTUALES</Link></li>
                    <li><Link to="/Citas_Admin_Doctor" className={getNavClass("/Citas_Admin_Doctor")} onClick={() => handleNavigation("/Citas_Admin_Doctor")}>CONSULTAS PRESENCIALES</Link></li>
                    <li><Link to="/Fecha_Admin_Doctor" className={getNavClass("/Fecha_Admin_Doctor")} onClick={() => handleNavigation("/Fecha_Admin_Doctor")}>FECHAS NO DISPONIBLES</Link></li>
                    <li><Link className="nav">DOCUMENTOS</Link>
                      <ul className="submenu">
                        <li className="link_submenu"><Link to="/Certif_Salud" className={getNavClass("/Certif_Salud")} onClick={() => handleNavigation("/Certif_Salud")}>CERTIF. SALUD</Link></li>
                        <li className="link_submenu"><Link to="/Solic_Examenes" className={getNavClass("/Solic_Examenes")} onClick={() => handleNavigation("/Solic_Examenes")}>Solic. Examenes</Link ></li>
                        <li className="link_submenu"><Link to="/HistoriaClinica" className={getNavClass("/HistoriaClinica")} onClick={() => handleNavigation("/HistoriaClinica")}>Historia Clinica</Link ></li>
                        <li className="link_submenu"><Link to="/RefMedicas" className={getNavClass("/RefMedicas")} onClick={() => handleNavigation("/RefMedicas")}>Ref. Medicas</Link ></li>
                        <li className="link_submenu"><Link to="/Recetarios" className={getNavClass("/Recetarios")} onClick={() => handleNavigation("/Recetarios")}>Recetarios</Link ></li>
                        <li className="link_submenu"><Link to="/CertificadoIncapacidad" className={getNavClass("/CertificadoIncapacidad")} onClick={() => handleNavigation("/CertificadoIncapacidad")}>Certificado Incapacidad</Link ></li>
                      </ul>
                    </li>
                    {isLoggedIn_admin_doctor ? (
                      <>
                        <li><a className="nombre_usuario">{user_admin_doctor.user_name_admin}
                        </a></li>
                        <li><Link onClick={Logout_Admin_Doctor} className="nombre_inicio">CERRAR SESIÓN</Link></li>
                      </>
                    ) : (
                      <>
                        <li><Link to="/Login_Admin_Doctor" className="nombre_inicio">INICIAR SESIÓN</Link></li>
                        <li><Link to="/Registro_Admin_Doctor" className="nombre_inicio">REGISTRARSE</Link></li>
                      </>
                    )}
                  </>
                ) : (

                  ["/admin", "/admin/", "/Admin/", "/Admin", "/Consultas_Admin", "/Citas_Admin", "/PacientesDataAdmin", "/Sugerencias_Admin", "/Login_Admin", "/Registro_Admin", "/Noticias_Admin", "/Blog_Admin"].includes(location.pathname) ? (
                    <>
                      <li><Link to="/Admin" className={getNavClass("/Admin")} onClick={() => handleNavigation("/Admin")}>INICIO</Link></li>
                      <li><Link to="/Consultas_Admin" className={getNavClass("/Consultas_Admin")} onClick={() => handleNavigation("/Consultas_Admin")}>CON. VIRTUALES</Link></li>
                      <li><Link to="/Citas_Admin" className={getNavClass("/Citas_Admin")} onClick={() => handleNavigation("/Citas_Admin")}>CON. PRESENCIALES</Link></li>
                      <li><Link to="/PacientesDataAdmin" className={getNavClass("/PacientesDataAdmin")} onClick={() => handleNavigation("/PacientesDataAdmin")}>PACIENTES</Link></li>
                      <li><Link to="/Sugerencias_Admin" className={getNavClass("/Sugerencias_Admin")} onClick={() => handleNavigation("/Sugerencias_Admin")}>SUGERENCIAS</Link></li>
                      <li><Link to="/Noticias_Admin" className={getNavClass("/Noticias_Admin")} onClick={() => handleNavigation("/Noticias_Admin")}>NOTICIAS</Link></li>
                      <li><Link to="/Blog_Admin" className={getNavClass("/Blog_Admin")} onClick={() => handleNavigation("/Blog_Admin")}>BLOG</Link></li>
                      {isLoggedIn_admin ? (
                        <>
                          <li><a className="nombre_usuario">{user_admin.user_name_admin}</a></li>
                          <li><Link onClick={Logout_Admin} className="nombre_inicio">CERRAR SESIÓN</Link></li>
                          <li><Link onClick={Logout_All_Admin} className="nombre_inicio">Logout All</Link></li>
                        </>
                      ) : (
                        <>
                          <li><Link to="/Login_Admin" className="nombre_inicio">INICIAR SESIÓN</Link></li>
                          <li><Link to="/Registro_Admin" className="nombre_inicio">REGISTRARSE</Link></li>
                        </>
                      )}
                    </>
                  ) : (
                    ["/Usuarios", "/Consultas", "/Citas_usuario", "/Recibo_pagos"].includes(location.pathname) ? (
                      <>
                        <li><Link to="/" className={getNavClass("/")} onClick={() => handleNavigation("/")}>INICIO</Link></li>
                        <li><Link to="/Usuarios" className={getNavClass("/Usuarios")} onClick={() => handleNavigation("/Usuarios")}>USUARIO</Link></li>
                        <li><Link to="/Consultas" className={getNavClass("/Consultas")} onClick={() => handleNavigation("/Consultas")}>CONSULTAS VIRTUALES</Link></li>
                        <li><Link to="/Citas_usuario" className={getNavClass("/Citas_usuario")} onClick={() => handleNavigation("/Citas_usuario")}>CONSULTAS PRESENCIALES</Link></li>
                        <li><Link to="/Recibo_pagos" className={getNavClass("/Recibo_pagos")} onClick={() => handleNavigation("/Recibo_pagos")}>RECIBOS DE PAGO</Link></li>
                        {isLoggedIn ? (
                          <>
                            <li><a className="nombre_usuario">{user.user_name}</a></li>
                            <li><Link onClick={Logout} className="nombre_inicio">CERRAR SESIÓN</Link></li>
                          </>
                        ) : (
                          <>
                            <li><Link onClick={openLogin} className="nombre_inicio">INICIAR SESIÓN</Link></li>
                            <li><Link onClick={openRegistro} className="nombre_inicio">REGISTRARSE</Link></li>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <li><Link to="/" className={getNavClass("/")} onClick={() => handleNavigation("/")}>INICIO</Link></li>
                        <li><Link to="/medicina-general" className={getNavClass("/medicina-general")} onClick={() => handleNavigation("/medicina-general")}>MEDICINA GENERAL</Link></li>
                        <li><Link to="/psicologia" className={getNavClass("/psicologia")} onClick={() => handleNavigation("/psicologia")}>PSICOLOGÍA</Link></li>
                        <li><Link to="/noticias" className={getNavClass("/noticias")} onClick={() => handleNavigation("/noticias")}>NOTICIAS</Link></li>
                        <li><Link to="/citas" className={getNavClass("/citas")} onClick={() => handleNavigation("/citas")}>CONSULTAS PRESENCIALES</Link></li>
                        <li><Link to="/consultas-virtuales" className={getNavClass("/consultas-virtuales")} onClick={() => handleNavigation("/consultas-virtuales")}>CONSULTAS VIRTUALES</Link></li>
                        {/*<li><Link to="/sugerencias" className={getNavClass("/sugerencias")} onClick={() => handleNavigation("/sugerencias")}>SUGERENCIAS</Link></li>*/}
                        {isLoggedIn ? (
                          <>
                            <li><Link to="/Usuarios" className="nombre_inicio" onClick={() => handleNavigation("/Usuarios")}>{user.user_name}</Link></li>
                            <li><Link onClick={Logout} className="nombre_inicio">CERRAR SESIÓN</Link></li>
                          </>
                        ) : (
                          <>
                            <li><Link onClick={openLogin} className="nombre_inicio">INICIAR SESIÓN</Link></li>
                            <li><Link onClick={openRegistro} className="nombre_inicio">REGISTRARSE</Link></li>
                          </>
                        )}
                      </>
                    )
                  )
                )}
              </ul>
            </nav>
          </div>
        </header>

        {/*Login y Registro Usuario*/}
        {showLogin && <Login closeLogin={closeLogin} />}
        {showRegistro && <Registro closeRegistro={closeRegistro} />}

      </>) : (
        <header id="mainConsultaChat">
          <div className="container">
            <img id="logo" src={`${process.env.PUBLIC_URL}/assets/images/LOGO_no_bg.png`} alt="LOGO#1" className="logo-svg" />
          </div>
        </header>
      )}
    </div>
  );
}
