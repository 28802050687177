import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../css/Admin/FloatingConsulta.css";

const FloatingConsulta = ({ token, onClose }) => {
  const [data, setData] = useState([]); // Inicializar como un arreglo vacío para evitar errores
  const [loading, setLoading] = useState(true); // Estado de carga

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        try {
          const response = await axios.get(
            "https://medico24x7.com/api/Admin/Nuevo_PHP/Pacientes_Admin_Boton.php",
            {
              params: { token }, // Pasar el token como query parameter
              withCredentials: true, // Si necesitas cookies
            }
          );
          setData(response.data); // Actualiza los datos
          setLoading(false); // Desactiva el estado de carga
        } catch (error) {
          console.error("Error al obtener datos:", error);
          setLoading(false); // Asegúrate de desactivar el estado de carga incluso en errores
        }
      }
    };

    fetchData();
  }, [token]);

  /*const handleDownload = async (id) => {
    // Llama al script PHP directamente para forzar la descarga
    const url = `https://medico24x7.com/api/Admin/descargar_archivo.php?id=${id}`;
    // Crea un enlace temporal y simula un clic
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', ''); // Esto asegura que el navegador entienda que es una descarga
    document.body.appendChild(a);
    a.click(); // Simula el clic en el enlace
    document.body.removeChild(a); // Elimina el enlace
  };
*/

  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://medico24x7.com/api/GetDropbox.php', {
          params: { token }, // Pasar el token como query parameter
          withCredentials: true
        });
        setImages(response.data.images);
        console.log('Imágenes obtenidas:', response.data.images);
      } catch (error) {
        console.error('Error al obtener las imágenes:', error);
      }
    };
    fetchImages();
  }, [token]);

  return (
    <>
      <div className="floating-overlay">
        <div className="floating-modal">
          <div className="floating-botton">
            <button onClick={onClose} className="floating-close-button">
              Cerrar
            </button>
          </div>
          <br />

          <table className="consultas_table">
            {loading ? (
              //animación de carga de datos
              <p>Cargando datos...</p>
            ) : data.length > 0 ? (
              data.map((consulta, index) => (
                <tbody key={consulta.id}>
                  <tr>
                    <td><strong>Acuerdo</strong></td>
                    <td>{consulta.Acuerdo}</td>
                  </tr>
                  <tr>
                    <td><strong>Uso Primera Consulta</strong></td>
                    <td>{consulta.Primera_Consulta === 0 ? "Sí" : "No"}</td>
                  </tr>
                  <tr>
                    <td><strong>Nombre</strong></td>
                    <td>{consulta.nombre} {consulta.apellido}</td>
                  </tr>
                  <tr>
                    <td><strong>Nacionalidad</strong></td>
                    <td>{consulta.nacionalidad}</td>
                  </tr>
                  <tr>
                    <td><strong>Cédula</strong></td>
                    <td>{consulta.cedula}</td>
                  </tr>
                  <tr>
                    <td><strong>Género</strong></td>
                    <td>{consulta.genero}</td>
                  </tr>
                  <tr>
                    <td><strong>Edad</strong></td>
                    <td>{consulta.edad}</td>
                  </tr>
                  <tr>
                    <td><strong>Dirección</strong></td>
                    <td>{consulta.direccion}</td>
                  </tr>
                  <tr>
                    <td><strong>Correo</strong></td>
                    <td>{consulta.correo}</td>
                  </tr>
                  <tr>
                    <td><strong>Celular</strong></td>
                    <td>{consulta.celular}</td>
                  </tr>
                  <tr>
                    <td><strong>Fecha de Registro</strong></td>
                    <td>{consulta.fecha_registro}</td>
                  </tr>

                  {/*Esta sección de descargar archivo estara en otro cuadro*/}
                  {/*<tr>
                  <td><strong>Archivo de exámenes</strong></td>
                  <td>
                    {consulta.archivo_examenes_encrypted ? (
                      <button onClick={() => handleDownload(consulta.id)}>Descargar</button>
                    ) : (<p>No hay archivo</p>)}
                  </td>
                </tr>*/}

                </tbody>
              ))
            ) : (<p>No se encontraron datos.</p>)}
          </table>
        </div>

        {images.length > 0 ? (
          <div className="floating-modal-2">
            <table className="consultas_table">
              {loading ? (
                //animación de carga de datos
                <p>Cargando datos...</p>
              ) : (
                images.map((image) => (
                  <div key={image.id}>
                    <p>Cédula</p>
                    <img src={image.url_img_cedula} alt={image.name_img_cedula} className="imagen_con_admin" />
                  </div>
                )))}
            </table>
          </div>
        ) : (<p>No se encontraron datos.</p>)}

      </div>
    </>
  );
};

export default FloatingConsulta;