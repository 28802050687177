import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../css/Patrocinios.css'; // Estilos CSS 

const Patrocinios = () => {
    const [patrocinios, setPatrocinios] = useState(true);

    useEffect(() => {
        const togglePatrocinios = () => {
            setPatrocinios((prev) => !prev);
        };

        // Mostrar por 3 minutos (180,000 ms), ocultar por 1 minuto (60,000 ms)
        const interval = setInterval(() => {
            togglePatrocinios();
        }, patrocinios ? 180000 : 60000); // Cambiar el intervalo según el estado actual

        // Limpiar intervalo al desmontar el componente
        return () => clearInterval(interval);
    }, [patrocinios]); // Dependencia para actualizar el intervalo según el estado actual


    const location = useLocation(); // Usa useLocation para obtener la ruta actual
    const [Promocion, setPromocion] = useState(false);

    useEffect(() => {
        const rutasOcultas = [
            "/admin",
            "/admin/",
            "/Admin/",
            "/Admin",
            "/Consultas_Admin",
            "/Citas_Admin",
            "/PacientesDataAdmin",
            "/Sugerencias_Admin",
            "/Login_Admin",
            "/Registro_Admin",
            "/Noticias_Admin",
            "/Blog_Admin"
        ];

        if (rutasOcultas.includes(location.pathname)) {
            setPromocion(false);
        } else {
            setPromocion(true);
        }
    }, [location.pathname]);

    return (
        <>
            {Promocion && (
                <>
                    {patrocinios && (
                        <>
                            <div className="main-patrocinios">
                                <div className='patrocinios'>
                                    {/*<img
                            id="logo-patrocinio"
                            src={`${process.env.PUBLIC_URL}/assets/images/Panel_Patrocinadores.png`}
                            alt="patrocinio"
                            className="img-patrocinio"
                        />*/}
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default Patrocinios;
