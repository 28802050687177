import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import '../css/Noticias_Blog.css';

export default function Noticias() {
  const [adminNews, setAdminNews] = useState([]); // Noticias subidas desde admin
  const [currentIndex, setCurrentIndex] = useState(0); // Índice de la noticia actual del carrusel
  const [blogPosts, setBlogPosts] = useState([]); // Almacenar los posts del blog

  const navigate = useNavigate();

  // Función para obtener las noticias subidas desde el admin
  const fetchAdminNews = async () => {
    try {
      const response = await fetch('https://medico24x7.com/api/fetch-admin-news.php', {
        withCredentials: true
      }); // Endpoint para las noticias desde admin

      if (!response.ok) {
        throw new Error('Error en la respuesta del servidor');
      }

      const articles = await response.json();
      setAdminNews(articles);
      //console.log(articles);
    } catch (error) {
      console.error('Error al obtener las noticias del admin:', error);
    }
  };

  // Función para obtener los posts del blog
  const fetchBlogPosts = async () => {
    try {
      const response = await fetch('https://medico24x7.com/api/fetch-blog-posts.php', {
        withCredentials: true
      });

      if (!response.ok) {
        throw new Error('Error en la respuesta del servidor');
      }

      const posts = await response.json();
      setBlogPosts(posts);
    } catch (error) {
      console.error('Error al obtener los posts del blog:', error);
    }
  };


  // Llamar a fetchNews y fetchAdminNews cuando el componente se monta
  useEffect(() => {
    fetchAdminNews();
    fetchBlogPosts();
  }, []);

  // Funciones para navegar por las noticias del admin
  const goToPrevious = () => {
    const newIndex = currentIndex === 0 ? adminNews.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const newIndex = currentIndex === adminNews.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  // Configuración del detector de gestos de deslizamiento
  const handlers = useSwipeable({
    onSwipedLeft: () => goToNext(),
    onSwipedRight: () => goToPrevious(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true, // Esto también permite detectar deslizamientos con el mouse
  });


  
 //Codigo para cerrear sesion despues de 30 minutos
const cerrarSesion = useCallback( async () => async () => {
  try {
    const response = await fetch('https://medico24x7.com/api/logout.php', {
      method: 'POST',
      credentials: 'include', // Incluir cookies
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();

    if (data.success) {
      alert('La sesión ha expirado. Serás redirigido al login.');
      navigate('/'); // Redirigir al login
    } else {
      alert('Error al cerrar la sesión');
    }
  } catch (error) {
    console.error('Error al cerrar la sesión:', error);
  }
}, [navigate]);

const timerRef = useRef(null);
  
// Define resetTimer con useCallback para evitar recrearlo en cada renderizado
const resetTimer = useCallback( async() => {
  clearTimeout(timerRef.current);
  timerRef.current = setTimeout(() => {
    cerrarSesion(); // Función para cerrar sesión (debes definirla o pasarla como dependencia si cambia)
  }, 1800000); // 30 minutos en milisegundos
}, [cerrarSesion]);

useEffect(() => {
  // Agregar eventos para detectar actividad del usuario
  window.addEventListener('mousemove', resetTimer);
  window.addEventListener('keypress', resetTimer);
  window.addEventListener('scroll', resetTimer);

  resetTimer(); // Establecer el temporizador al montar el componente

  // Limpiar eventos y temporizador al desmontar el componente
  return () => {
    clearTimeout(timerRef.current);
    window.removeEventListener('mousemove', resetTimer);
    window.removeEventListener('keypress', resetTimer);
    window.removeEventListener('scroll', resetTimer);
  };
}, [resetTimer]); // Incluir resetTimer como dependencia

  return (
    <div className='body_noticias'>
      <main id="noticias">

        {/* Sección de noticias del admin con carrusel */}
        <br />
        {adminNews.length > 0 ? (
        <section id="noticias-admin" className="news-section-admin">
          <h2>Últimas Noticias</h2>
            <div {...handlers} className="news-item-admin">
              
              {/* Botones de anterior */}
              <div className="news-navigation">
                <button onClick={goToPrevious} className="nav-button"><strong>Anterior</strong></button>
              </div>

              <div className='articuloInstruccion'>
                <p><strong>Desliza para ir a la siguiente noticia o a la anterior</strong></p>
              </div>

              {/* Mostrar la noticia actual según el currentIndex */}
              <div className="articuloAdmin">
                <div className='articuloTitulo'>
                  <h3>{adminNews[currentIndex].titulo}</h3>
                </div>
                <div className="aritculoContenido">
                <div className="aritculoDescripcion">
                  <p><strong>Descripción:</strong></p>
                  <p>{adminNews[currentIndex].descripcion || 'Descripción no disponible.'}</p>
                </div>
          {adminNews[currentIndex].imagen_url ? (
            <img className='admin-news-image'
              src={`data:image/png;base64,${adminNews[currentIndex].imagen_url}`} 
              style={{ width: '150px', height: 'auto' }} 
              alt={`Imagen de la noticia: ${adminNews[currentIndex].titulo}`} // Cambia `titulo` por el atributo adecuado
            />
            ) : (
            <p>Cargando imagen...</p>
          )}
                </div>
              </div>

              {/* Botones de siguiente */}
              <div className="news-navigation">
                <button onClick={goToNext} className="nav-button"><strong>Siguiente</strong></button>
              </div>
            </div>
        </section>
      ) : (
            "Cargando barra de noticias..."
          )}
        <br />
        
{/* Sección de blog */}
{blogPosts.length > 0 ? (
  <section id="blog" className="blog-section">
    <h2>Blog</h2>
    <div className='blog-container-main'>
    <div className="blog-container">
      {blogPosts.map((post, index) => (
        <article key={index} className="blog-post">
          <h3>{post.titulo}</h3>
          <p className="blog-date">{new Date(post.fecha_creacion).toLocaleDateString()}</p> {/* Muestra la fecha de publicación */}
          {post.imagen_url && (
            <img 
              className='blog-image'
              src={`data:image/png;base64,${post.imagen_url}`} 
              alt={`Imagen del post ${post.titulo}`} 
            />
          )}
          <p className="blog-description">{post.contenido || 'Descripción no disponible.'}</p>
          {/*<p className="blog-content">{post.contenido}</p>*/} {/* Muestra el contenido completo */}
        </article>
      ))}
    </div>
    </div>
  </section>
) : (
  <p>Cargando posts del blog...</p>
)}

      </main>
    </div>
  );
}
